<template>
  <div class="tabled">
    <div class="tabled-cell Nunito">
    <img src="https://i.giphy.com/media/9ZOyRdXL7ZVKg/source.gif">
      <h1 class="title is-size-3">Ummmm. Our server broke??</h1>
      <h1 class="subtitle is-size-5 has-text-grey-light">You broke it not us 100% your fault, don't blame us!<br>(╯°□°）╯︵ ┻━┻</h1>
    </div>
  </div>
</template>
<script>
  export default {
    name: '404',
    components: {}
  }
</script>

<style scoped>
.tabled {
  display: table;
  height: 35rem;
  margin: 0 auto;
}
.tabled-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
</style>
